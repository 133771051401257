import React from 'react';
import ReactMarkdown from 'react-markdown';
import './PostBody.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link, useOutletContext } from 'react-router-dom';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const PostBody = (props) => {
  let dateObject = new Date(props.post.published_at);

  const { isLoggedIn } = useOutletContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let formattedDate = dateObject.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  let badgeColor = [
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
  ];
  const badgeCount = badgeColor.length;
  const categoriesCount = props.categories.length;
  for (let i = 0; i <= categoriesCount - badgeCount; i++) {
    badgeColor.push(badgeColor[i % (badgeCount + 1)]);
  }

  if (!props.post) {
    return <div style={{ minHeight: '90vh' }}>loading...</div>;
  }

  const schemaMarkup = {
    '@context': 'https://schema.org',
    '@type': 'BlogPosting',
    headline: props.post.title,
    datePublished: props.post.published_at,
    dateModified: props.post.updated_at,
    author: {
      '@type': 'Person',
      name: 'علی قوامیان',
    },
    publisher: {
      '@type': 'Organization',
      name: 'تکسامش',
      logo: {
        '@type': 'ImageObject',
        url: 'https://taksamesh.com/logo512.png',
      },
    },
    description: props.post.content.slice(0, 150),
    articleBody: props.post.content,
  };

  return (
    <div className="container post-view mt-5" dir="rtl">
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
      </Helmet>
      <div className="row">
        <div className="col">
          <h1 className="post-title">{props.post.title}</h1>
          {props.post.image_url && (
            <LazyLoadImage
              src={props.post.image_url}
              alt={props.post.title}
              className="img-fluid post-image mb-4"
              effect="blur"
            />
          )}
          {props.post.excerpt && (
            <div className="post-excerpt mb-4 iranfontregular">
              <ReactMarkdown>{props.post.excerpt}</ReactMarkdown>
            </div>
          )}
          <div className="post-content iranfontregular">
            <ReactMarkdown>{props.post.content}</ReactMarkdown>
          </div>
          {isLoggedIn && (
            <Link
              to={`/posts/edit/${props.post.id}`}
              className="iranfontregular btn btn-info mb-5"
            >
              اصلاح پست
            </Link>
            // TODO: add remove post
          )}
        </div>
      </div>
    </div>
  );
};

export default PostBody;
